// App.js
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PasswordComponent from './PasswordComponent'
import Demo from './Demo'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PasswordComponent />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
