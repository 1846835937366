// Component to create a timer that counts seconds, minutes, and hours
import React, { useState, useEffect } from 'react'

const Timer = () => {
  const [seconds, setSeconds] = useState(0)
  const [minutes, setMinutes] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment seconds
      setSeconds(prevSeconds => prevSeconds + 1)

      // Check if 60 seconds have passed to increment minutes
      if (seconds === 59) {
        setSeconds(0)
        setMinutes(prevMinutes => prevMinutes + 1)

        // Check if 60 minutes have passed to increment hours
      }
    }, 1000)

    return () => clearInterval(interval) // Cleanup interval on component unmount
  }, [seconds, minutes])

  return <em className="">{`${minutes}:${seconds}`}</em>
}

export default Timer
