import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from '@mantine/form'
import { TextInput, Button, LoadingOverlay, Select } from '@mantine/core'

const FormComponent = props => {
  const { formArray, validForm, initialForm, onSubmit, formLoader } = props
  const [formValue, setFormValue] = useState(initialForm)

  const form = useForm({
    validateInputOnBlur: true,
    initialValues: formValue,
    validate: validForm,
  })
  const handleSubmit = value => {
    setFormValue(value)
    if (onSubmit) {
      onSubmit(value)
    }
  }
  return (
    <div>
      <LoadingOverlay
        visible={formLoader ?? false}
        loaderProps={{ children: 'Loading...' }}
      />
      <form onSubmit={form.onSubmit(handleSubmit)} className="space-y-6">
        {formArray.map(val => {
          if (val.typeInput === 'select') {
            return (
              <Select
                radius="sm"
                withAsterisk
                key={val.id}
                mt="sm"
                label={val.label}
                placeholder={val.placeholder}
                {...form.getInputProps(val.id)}
                data={val.selectData}
                searchable
                nothingFoundMessage="Nothing found..."
              />
            )
          } else {
            return (
              <TextInput
                radius="sm"
                withAsterisk
                key={val.id}
                mt="sm"
                label={val.label}
                placeholder={val.placeholder}
                {...form.getInputProps(val.id)}
              />
            )
          }
        })}
        <div>
          <Button
            type="submit"
            radius="xl"
            fullWidth
            color="red"
            variant="outline"
            mt="sm"
          >
            Ajouter
          </Button>
        </div>
      </form>
    </div>
  )
}

FormComponent.propTypes = {
  formArray: PropTypes.array.isRequired,
  validForm: PropTypes.object.isRequired,
  initialForm: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  formLoader: PropTypes.bool,
}

export default FormComponent
