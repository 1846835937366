/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { productService } from '../service/product-service'
import { callService } from '../service/call-service'
import { serviceCommand } from '../service/command-service'
import MicRecorder from 'mic-recorder-to-mp3'
import { Container, Button, Paper, Text, Group, Badge } from '@mantine/core'
import WaveForm from '../components/audio/WaveForm'
import giftAnimation from '../icons8-audio-wave.gif'
import AudioPlayer from '../components/audio/AudioPlayer'
import {
  IconMicrophone,
  IconPaperclip,
  IconSend,
  IconTrashFilled,
  IconUser,
} from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import ModalComponent from '../components/modal/modal-component'

import Timer from './chat/components/timer'

function Demo() {
  const conversationID = Date.now()
  const [messages, setMessage] = useState([])
  const [areaValue, setAreaValue] = useState('')
  const [disabledButton, setdisabledButton] = useState(true)
  const [loader, setLoader] = useState({
    loaderCall: false,
    loaderMsg: false,
  })
  console.log({ messages })
  let userAudio
  const [product_opened, { open: product_open, close: product_close }] =
    useDisclosure(false)
  const [products, setProducts] = useState([])
  const [orders_opened, { open: orders_open, close: orders_close }] =
    useDisclosure(false)
  const [orders, setOrders] = useState([])
  const productTitles = products.map(product => product.title)

  const product_modalBody = [
    {
      id: 'title',
      label: 'Nom du produit',
      placeholder: 'Nom du produit',
    },
    {
      id: 'description',
      label: 'Description',
      placeholder: 'Description',
    },
    {
      id: 'price',
      label: 'Prix',
      placeholder: 'Prix',
    },
    {
      id: 'qte',
      label: 'Qte',
      placeholder: 'Qte',
    },
  ]
  const product_initialForm = {
    title: '',
    description: '',
    price: '',
    qte: '',
  }
  const product_validateForm = {
    title: value =>
      value.length < 2 ? 'Nom du produit must have at least 2 letters' : null,
    description: value =>
      value.length < 2 ? 'Description must have at least 2 letters' : null,
    price: value =>
      isNaN(value) || +value < 1
        ? 'You must enter a valid number greater than or equal to 1'
        : null,
    qte: value =>
      isNaN(value) || +value < 1
        ? 'Qte must enter a valid number greater than or equal to 1'
        : null,
  }
  const orderStartus = ['active', 'refunded', 'canceled', 'shipped', 'paid']
  const orderStatusColor = ['green', 'yellow', 'red', 'gray', 'blue']
  const order_modalBody = [
    {
      id: 'numberCommand',
      label: 'N° Commande',
      placeholder: 'Number',
    },
    {
      id: 'price',
      label: 'Prix',
      placeholder: 'Total',
    },
    {
      id: 'list',
      label: 'Liste produits',
      placeholder: 'Liste produits',
      typeInput: 'select',
      selectData: productTitles,
    },
    {
      id: 'name',
      label: 'Nom complet',
      placeholder: 'Nom complet',
    },
    {
      id: 'email',
      label: 'Email',
      placeholder: 'toto@gmail.com',
    },
    {
      id: 'status',
      label: 'Etat de commande',
      placeholder: 'Etat de commande',
      typeInput: 'select',
      selectData: orderStartus,
    },
  ]
  const order_initialForm = {
    numberCommand: '',
    price: '',
    list: '',
    name: '',
    email: '',
    status: '',
  }
  const order_validateForm = {
    numberCommand: value =>
      value.length < 4 ? 'Number must have at least 4 letters' : null,
    price: value =>
      isNaN(value) || +value < 1
        ? 'You must enter a valid number greater than or equal to 1'
        : null,
    list: value => (!value.length ? 'You have to choose a product' : null),
    name: value =>
      value.length < 2 ? 'Nom complet must have at least 2 letters' : null,
    email: value => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    status: value => (!value.length ? 'You have to choose a staus' : null),
  }
  const [record, setRecord] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 }),
  )
  const [state, setState] = useState({
    isRecording: false,
    blobURL: '',
    isBlocked: false,
    ai_response_audio: undefined,
  })
  const [analyzerData, setAnalyzerData] = useState(null)
  const audioElmRef = useRef(null)
  const chatContainerRef = useRef(null)

  // const conversationID = Date.now().toString()
  // eslint-disable-next-line no-unused-vars
  const audioAnalyzer = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    const analyzer = audioCtx.createAnalyser()
    analyzer.fftSize = 2048
    const bufferLength = analyzer.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)
    const source = audioCtx.createMediaElementSource(audioElmRef.current)
    source.connect(analyzer)
    source.connect(audioCtx.destination)
    source.onended = () => {
      source.disconnect()
    }

    setAnalyzerData({ analyzer, bufferLength, dataArray })
  }
  const userAudioAnalyzer = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    const analyzer = audioCtx.createAnalyser()
    analyzer.fftSize = 2048
    const bufferLength = analyzer.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)
    const source = audioCtx.createMediaElementSource(audioElmRef.current)
    source.connect(analyzer)
    source.connect(audioCtx.destination)
    source.onended = () => {
      source.disconnect()
    }

    setAnalyzerData({ analyzer, bufferLength, dataArray })
  }
  const handleChange = async e => {
    const inputValue = e.target.value
    setAreaValue(inputValue)
    inputValue.length ? setdisabledButton(false) : setdisabledButton(true)
  }

  const start = async () => {
    await getMicrophonePermission()

    if (state.isBlocked) {
      console.log('Permission Denied')
    } else {
      console.log('Going to start recording')
      setRecord(true)
      Mp3Recorder.start()
        .then(() => {
          setState({ isRecording: true })
          console.log('Started recording')
        })
        .catch(e => console.error(e))
    }
  }

  const stop = () => {
    console.log('STOP')
    try {
      if (state.isRecording) {
        setRecord(false)
        Mp3Recorder.stop()
          .getMp3()
          // eslint-disable-next-line no-unused-vars
          .then(async ([buffer, blob]) => {
            // const blobURL = URL.createObjectURL(blob)
            userAudio = blob
            setState({ isRecording: false })
            setLoader(prevLoad => ({
              ...prevLoad,
              loaderCall: true,
            }))
            console.log('state.conversationID is ', conversationID)
            const response = await callService.getTranscription(blob)
            // const transcribed_text = await callService.getIAResponse(
            //   blob,
            //   conversationID,
            //   [],
            //   [],
            // )
            if (!response) {
              setLoader(prevLoad => ({
                ...prevLoad,
                loaderCall: false,
              }))
              return
            }
            console.log('transcribed_text is ', response)
            setMessage(prev => [
              ...prev,

              {
                src: 'human',
                text: response.transcribed_text,
              },
            ])
            await new Promise(resolve => setTimeout(resolve, 1000))
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight
            const listProduct = productService.listProduct()
            const listCommand = serviceCommand.listCommand()
            const ia_response = await callService.getTTSResponse(
              [
                ...messages,
                {
                  src: 'human',
                  text: response.transcribed_text,
                },
              ].map(message => {
                return {
                  role: message.src === 'ia' ? 'assistant' : 'user',
                  content: message.text,
                }
              }),
              listProduct.map(e => JSON.stringify(e)),
              listCommand.map(e => JSON.stringify(e)),
            )

            if (!ia_response) {
              setLoader(prevLoad => ({
                ...prevLoad,
                loaderCall: false,
              }))
              return
            }
            // const jsonResponse = await ia_response.data
            console.log('JSON Response:', ia_response)
            const audioUrl = window.URL.createObjectURL(
              new Blob([ia_response.data]),
            )

            console.log('audioUrl is: ', audioUrl)
            setState({ blobURL: audioUrl, ai_response_audio: ia_response })

            setMessage(prev => [
              ...prev,

              {
                src: 'ia',
                audioURL: audioUrl,
              },
            ])
            await new Promise(resolve => setTimeout(resolve, 1000))
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight
            console.log('state.conversationID is ', conversationID)
            const ia_response_stt = await callService.getTranscription(
              new Blob([ia_response.data]),
            )
            // const transcribed_text = await callService.getIAResponse(
            //   blob,
            //   conversationID,
            //   [],
            //   [],
            // )
            console.log('transcribed_text is ', response)
            setMessage(prev => [
              ...prev,

              {
                src: 'ia',
                text: ia_response_stt.transcribed_text,
              },
            ])
            await new Promise(resolve => setTimeout(resolve, 1000))
            chatContainerRef.current.scrollTop =
              chatContainerRef.current.scrollHeight
            setLoader(prevLoad => ({
              ...prevLoad,
              loaderCall: false,
            }))
            audioAnalyzer()
            if (audioElmRef.current) {
              //  audioElmRef.current.play()
            }
          })
          .catch(e => console.log(e))
      } else {
        console.log('Not recording')
      }
    } catch (error) {
      console.error(
        "Une erreur est survenue lors de l'arrêt de l'enregistrement :",
        error,
      )
    }
  }

  async function getMicrophonePermission() {
    try {
      await navigator.mediaDevices.getUserMedia(
        //  const stream = await navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          console.log('Permission Granted')
          setState({ isBlocked: false })
        },
        () => {
          console.log('Permission Denied')
          setState({ isBlocked: true })
        },
      )
      // Utilisez la référence vidéo si nécessaire, par exemple :
      //videoRef.current.srcObject = stream
    } catch (error) {
      console.error(
        "Erreur lors de l'obtention de la permission du microphone:",
        error,
      )
      // setError(error.message) // Enregistrez le message d'erreur dans le state
    }
  }

  const handleMessage = async () => {
    console.log('new msg', areaValue)
    const theTime = new Date()
    setMessage(prev => [
      ...prev,
      {
        src: 'human',
        text: areaValue,
        time: theTime.getHours() + ':' + theTime.getMinutes(),
      },
    ])
    setAreaValue('')
    await new Promise(resolve => setTimeout(resolve, 1000))
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    const listProduct = productService.listProduct()
    const listCommand = serviceCommand.listCommand()
    setLoader(prevLoad => ({
      ...prevLoad,
      loaderMsg: true,
    }))
    const response = await productService.getIAResponse({
      message: {
        emails: [
          {
            email_id: 'guillaume.boned@orange.fr',
            email_subject: '',
            email_body: areaValue,
            email_sender: 'Guillaume Boned <guillaume.boned@orange.fr',
          },
        ],
        order_data: listProduct,
        products_data: listCommand,
        history: [
          ...messages,
          {
            src: 'human',
            text: areaValue,
          },
        ].map(message => {
          const the_content_element = message.text
            ? {
                type: 'text',
                text: message.text,
              }
            : {
                type: 'image_url',
                image_url: {
                  url: message.imageUrl,
                },
              }
          return {
            role: message.src === 'ia' ? 'assistant' : 'user',
            content: [the_content_element],
          }
        }),
      },
    })
    console.log('respons is', response)
    setLoader(prevLoad => ({
      ...prevLoad,
      loaderMsg: false,
    }))
    if (response && response.result) {
      const theTime = new Date()

      setMessage(prev => [
        ...prev,

        {
          src: 'ia',
          text: response.result,
          time: theTime.getHours() + ':' + theTime.getMinutes(),
        },
      ])
    }
    console.log('messages are', messages)
    await new Promise(resolve => setTimeout(resolve, 1000))
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleMessage()
    }
  }

  const removeOrder = product => {
    if (confirm('Voulez-vous vraiment effectuer cette action ?')) {
      console.log('Product remove')
      const new_list = orders.filter(element => element.id !== product.id)
      setOrders(new_list)
      serviceCommand.removeOrder(product)
    }
  }
  const handleStatusColor = (state, status, colors) => {
    const index = status.indexOf(state)
    return colors[index]
  }
  const handleOrderFormSubmit = async formValue => {
    const orderColor = handleStatusColor(
      formValue.status,
      orderStartus,
      orderStatusColor,
    )
    const newFormValue = { ...formValue, color: orderColor }
    setOrders(prevData => [...prevData, newFormValue])
    serviceCommand.createCommand(newFormValue)

    orders_close()
  }

  const handleProductFormSubmit = async formValue => {
    setProducts(prevData => {
      if (prevData) {
        return [...prevData, formValue]
      } else {
        return [formValue]
      }
    })
    productService.createProduct(formValue)
    product_close()
  }

  const removeProduct = product => {
    if (confirm('Voulez-vous vraiment effectuer cette action ?')) {
      console.log('Product remove')
      const new_list = products.filter(element => element.id !== product.id)
      setProducts(new_list)
      productService.removeProduct(product)
    }
  }

  const handleImage = async e => {
    const listProduct = productService.listProduct()
    const listCommand = serviceCommand.listCommand()
    setLoader(prevLoad => ({
      ...prevLoad,
      loaderMsg: true,
    }))
    const maxSizeInBytes = 20 * 1024 * 1024
    const selectedFiles = Array.from(e.target.files)
    const selectedFilesBase64 = []

    if (selectedFiles.length) {
      selectedFiles.forEach(async file => {
        // Vérifier le type de fichier
        if (
          !['image/png', 'image/jpeg', 'image/jpg', 'image/webp'].includes(
            file.type,
          )
        ) {
          alert(
            'Veuillez sélectionner un fichier de type PNG, JPEG, JPG ou WEBP.',
          )
          return
        }
        // Vérifier la taille maximale du fichier (20 Mo)

        if (file.size > maxSizeInBytes) {
          alert('La taille du fichier dépasse la limite de 20 Mo.')
          return
        }
        const reader = new FileReader()
        let base64String
        reader.onload = function (e) {
          base64String = e.target.result
          console.log('base64String onload is', base64String)
        }
        reader.readAsDataURL(file)
        await new Promise(resolve => setTimeout(resolve, 1000))
        console.log('base64String is', base64String)
        selectedFilesBase64.push(base64String)
        setMessage(prev => [
          ...prev,

          {
            src: 'human',
            imageUrl: base64String,
          },
        ])
      })
      await new Promise(resolve => setTimeout(resolve, 1000))
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
      console.log('selectedFilesBase64 is', selectedFilesBase64)
      const response = await productService.getIAResponse({
        message: {
          emails: [
            {
              email_id: 'guillaume.boned@orange.fr',
              email_subject: '',
              email_body: areaValue,
              email_sender: 'Guillaume Boned <guillaume.boned@orange.fr',
            },
          ],
          order_data: listProduct,
          products_data: listCommand,
          history: [
            ...messages,
            ...selectedFilesBase64.map(el => ({
              src: 'human',
              imageUrl: el,
            })),
          ].map(message => {
            const the_content_element = message.text
              ? {
                  type: 'text',
                  text: message.text,
                }
              : {
                  type: 'image_url',
                  image_url: {
                    url: message.imageUrl,
                  },
                }
            return {
              role: message.src === 'ia' ? 'assistant' : 'user',
              content: [the_content_element],
            }
          }),
        },
      })

      console.log('respons is', response)
      setLoader(prevLoad => ({
        ...prevLoad,
        loaderMsg: false,
      }))
      if (response && response.result) {
        const theTime = new Date()

        setMessage(prev => [
          ...prev,

          {
            src: 'ia',
            text: response.result,
            time: theTime.getHours() + ':' + theTime.getMinutes(),
          },
        ])
      }
      console.log('messages are', messages)
      await new Promise(resolve => setTimeout(resolve, 1000))
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }
  useEffect(() => {
    const list = productService.listProduct()
    setProducts(list)
    const order_list = serviceCommand.listCommand()
    setOrders(order_list)
  }, [])

  return (
    <div className="min-h-screen bg-[#80808012]">
      {/* title session */}

      <div className="text-2xl text-center bg-[#80808012]">
        <div className="text-5xl font-semibold mb-2 md:mb-0">DEMO</div>
      </div>
      {/* Btn session */}

      <div className="bg-[#80808012]">
        <Group gap="md" justify="center" ml="lg" className="max-w-4xl">
          <Button
            variant="outline"
            color="green"
            onClick={() => product_open()}
            radius="sm"
          >
            Ajouter un produit
          </Button>
          <Button variant="outline" radius="sm" onClick={() => orders_open()}>
            Ajouter une commande
          </Button>
        </Group>
      </div>

      <div className="py-6 px-4 flex flex-col md:flex-row bg-[#da9c9c12] space-y-4 md:space-y-0">
        {/* list */}

        <div className="grow max-w-full md:max-w-[50%]">
          <div className="flex flex-col space-y-6">
            {/* products session */}
            <div className="bg-gray-50 border border-gray-200 rounded-xl shadow overflow-x-auto">
              {products && products.length ? (
                <div className="text-center p-3">
                  <div className="flex space-x-6 overflow-y-auto h-28">
                    {products.map(val => (
                      <div
                        key={val.id}
                        className="max-w-sm bg-gray-200 border-gray-200 rounded-xl shadow border p-3 w-36"
                      >
                        <div className="flex flex-col">
                          <div className="flex">
                            <Text className="text-md text-justify font-semibold tracking-tight truncate overflow-hidden w-[28rem]">
                              {val.title}
                            </Text>
                            <IconTrashFilled
                              size={18}
                              onClick={() => removeProduct(val)}
                              className="w-28 text-red-500 cursor-pointer"
                            />
                          </div>

                          <Text className="text-sm font-medium text-justify tracking-tight truncate overflow-hidden">
                            {val.description}
                          </Text>
                          <Text className="text-sm text-justify tracking-tight truncate overflow-hidden">
                            Qte : {val.qte}
                          </Text>
                          <Text className="text-sm text-justify font-bold tracking-tight truncate overflow-hidden">
                            {val.price}€
                          </Text>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="bg-white border border-gray-200 rounded-xl shadow text-center h-64">
                  <div className="my-20">
                    <span className="text-sm font-semibold">
                      {'Aucun Produit ajouté'}
                    </span>
                  </div>
                </div>
              )}

              <ModalComponent
                radius="xl"
                title="Produit"
                close={product_close}
                opened={product_opened}
                body={product_modalBody}
                formValidate={product_validateForm}
                initialForm={product_initialForm}
                formSubmit={handleProductFormSubmit}
              />
            </div>

            {/* orders session */}
            <div className="bg-gray-50 border border-gray-200 rounded-xl shadow overflow-x-auto">
              {orders.length ? (
                <div className="inline-block p-3 text-center">
                  <div className="flex space-x-6 overflow-y-auto h-28">
                    {orders.map(val => (
                      <div
                        className="w-36 max-w-xs bg-gray-200 border-gray-200 rounded-xl shadow p-3"
                        key={val.id}
                      >
                        <a href="#">
                          <div className="flex">
                            <Text className="text-md text-justify font-semibold tracking-tight truncate overflow-hidden w-[28rem]">
                              Order #{val.numberCommand}
                            </Text>
                            <IconTrashFilled
                              size={18}
                              onClick={() => removeOrder(val)}
                              className="mt-1 text-red-500 cursor-pointer w-28"
                            />
                          </div>
                        </a>
                        <Text className="text-sm text-justify truncate overflow-hidden">
                          {val.name}
                        </Text>
                        <Text className=" text-sm text-justify truncate overflow-hidden">
                          {val.email}
                        </Text>
                        <Badge color={val.color} size="sm">
                          {val.status}
                        </Badge>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-center h-40">
                  <div className="my-12">
                    <span className="text-sm font-semibold">
                      {'Aucune commande ajoutée'}
                    </span>
                  </div>
                </div>
              )}

              <ModalComponent
                title="Commande"
                close={orders_close}
                opened={orders_opened}
                body={order_modalBody}
                formValidate={order_validateForm}
                initialForm={order_initialForm}
                formSubmit={handleOrderFormSubmit}
              />
            </div>
          </div>
        </div>
        {/* Chat session */}

        <div className="grow max-w-full md:max-w-[50%]">
          <Container>
            <div className="bg-white border border-gray-200 w-full rounded-xl shadow">
              <Paper
                p="sm"
                className={`mx-auto mt-2 bg-white w-full rounded-xl`}
              >
                <div
                  ref={chatContainerRef}
                  className="h-[72vh] overflow-y-auto space-y-3 mb-3"
                >
                  {messages.map((val, index) => (
                    <div
                      style={{
                        display: `${val.audioURL ? 'none' : 'flex'}`,
                      }}
                      className={`flex items-start gap-2.5 ${
                        val.src === 'ia' ? 'justify-end' : ''
                      } `}
                      key={index}
                    >
                      <IconUser className="w-6 h-6 bg-gray-200  rounded-full" />
                      {val.text ? (
                        <div
                          className={`${
                            val.src === 'ia' ? 'bg-[#80808073]' : 'bg-gray-200'
                          } flex flex-col w-full max-w-[320px] leading-1.5 p-4  rounded-e-3xl rounded-es-3xl`}
                        >
                          <div className="flex items-center space-x-2 rtl:space-x-reverse">
                            <span className="text-sm font-semibold text-dark">
                              {val.src === 'human'
                                ? 'Utilisateur'
                                : 'Assistant'}
                            </span>
                            <span
                              className={`${
                                val.src === 'ia'
                                  ? 'text-black'
                                  : 'text-gray-500'
                              } text-sm font-normal text-gray-500`}
                            >
                              {val.time}
                            </span>
                          </div>
                          <p
                            className={`${
                              val.src === 'ia' ? 'text-black' : ''
                            } text-sm font-normal py-2.5`}
                          >
                            {val.text}
                          </p>
                          <span
                            className={`${
                              val.src === 'ia' ? 'text-black' : 'text-gray-500'
                            } text-sm font-normal `}
                          ></span>
                        </div>
                      ) : val.audioURL ? (
                        <div
                          className={`${
                            val.src === 'ia' ? 'bg-[#80808073]' : 'bg-gray-200'
                          } flex flex-col w-full max-w-[320px] leading-1.5 p-4  rounded-e-3xl rounded-es-3xl`}
                        >
                          <div className="flex items-center space-x-2 rtl:space-x-reverse">
                            <span className="text-sm font-semibold text-dark">
                              {val.src === 'human'
                                ? 'Utilisateur'
                                : 'Assistant'}
                            </span>
                            <span
                              className={`${
                                val.src === 'ia'
                                  ? 'text-black'
                                  : 'text-gray-500'
                              } text-sm font-normal text-gray-500`}
                            >
                              {val.time}
                            </span>
                          </div>
                          <p
                            className={`${
                              val.src === 'ia' ? 'text-black' : ''
                            } text-sm font-normal py-2.5`}
                          >
                            <AudioPlayer audioURL={val.audioURL} />
                          </p>
                          <span
                            className={`${
                              val.src === 'ia' ? 'text-black' : 'text-gray-500'
                            } text-sm font-normal `}
                          ></span>
                        </div>
                      ) : (
                        val.imageUrl && (
                          <div className="flex space-x-6 h-64">
                            <img
                              key={index}
                              className="max-w-xs transition-all object-cover object-center duration-300 rounded-lg cursor-pointer"
                              src={val.imageUrl}
                              alt={`image-${index}`}
                            />
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>

                <div className="flex justify-around space-x-2">
                  <div className="w-full">
                    <form className="max-w-3xl mx-auto">
                      <textarea
                        id="message"
                        rows="2"
                        value={areaValue}
                        className={`${
                          areaValue.length ? '' : 'placeholder:align-middle'
                        } block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-3xl placeholder:italic`}
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        placeholder="Écrire à l'assistant..."
                      />
                    </form>
                  </div>

                  <div className="flex mt-3 space-x-2">
                    {!state.isRecording && (
                      <>
                        <input
                          accept=".png, .jpg, .jpeg, .webp, .pjg"
                          id="button-file"
                          type="file"
                          multiple
                          style={{ display: 'none' }}
                          onChange={handleImage}
                        />
                        <label htmlFor="button-file">
                          <Button
                            radius="xl"
                            variant="outline"
                            color="indigo"
                            component="span"
                          >
                            <IconPaperclip size={20} />
                          </Button>
                        </label>
                      </>
                    )}

                    {!state.isRecording && (
                      <Button
                        radius="xl"
                        variant="outline"
                        color="blue"
                        disabled={disabledButton}
                        onClick={() => handleMessage()}
                        loading={loader.loaderMsg}
                        loaderProps={{ type: 'dots' }}
                      >
                        <IconSend size={20} />
                      </Button>
                    )}

                    {!state.isRecording ? (
                      <Button
                        radius="xl"
                        variant="outline"
                        color="green"
                        onClick={() => start()}
                        disabled={state.isRecording}
                        loading={loader.loaderCall}
                        loaderProps={{ type: 'dots' }}
                      >
                        <IconMicrophone size={20} />
                      </Button>
                    ) : (
                      <Button
                        radius="xl"
                        variant="outline"
                        color="blue"
                        onClick={() => stop()}
                      >
                        <img className="w-10" src={giftAnimation} />

                        <Timer />
                      </Button>
                    )}
                    <audio
                      style={{ display: 'none' }}
                      src={state.blobURL}
                      controls="controls"
                      ref={audioElmRef}
                    />

                    {analyzerData && <WaveForm analyzerData={analyzerData} />}
                  </div>
                </div>
              </Paper>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Demo
