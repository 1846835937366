export default class CommandService {
  listCommand = () => {
    // localStorage.removeItem('all_orders')
    const data = localStorage.getItem('all_orders')
    const dataMap = JSON.parse(data)
    let prods = dataMap ?? []
    console.log('actual order list is', prods)
    return prods
  }

  createCommand = order => {
    order.id = new Date().getMilliseconds()
    const newOrder = [...this.listCommand(), order]
    localStorage.setItem('all_orders', JSON.stringify(newOrder))
    console.log('actual products list is', JSON.stringify(newOrder))
    return order
  }

  removeOrder = order => {
    const products = this.listCommand()
    const new_list = products.filter(element => element.id !== order.id)
    localStorage.setItem('all_orders', JSON.stringify(new_list))
    console.log('new orders list is', JSON.stringify(new_list))
    return order
  }
}
export const serviceCommand = new CommandService()
