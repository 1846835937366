import React, { useRef, useState, useEffect } from 'react'

const AudioPlayer = ({ audioURL }) => {
  const audioElmRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    // Mettre à jour la source audio lorsqu'une nouvelle URL est fournie
    audioElmRef.current.src = audioURL
    // Recharger le lecteur audio après avoir mis à jour la source
    audioElmRef.current.load()
    playAudio()
  }, [audioURL])

  const playAudio = async () => {
    await new Promise(resolve => setTimeout(resolve, 1000))
    audioElmRef.current.play()
  }
  const togglePlayPause = () => {
    if (isPlaying) {
      audioElmRef.current.pause()
    } else {
      audioElmRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div>
      <audio controls ref={audioElmRef} onEnded={() => setIsPlaying(false)} />
      <button onClick={() => togglePlayPause()}>
        {isPlaying ? 'Pause' : 'Play'}
      </button>
    </div>
  )
}

export default AudioPlayer
