import axios from 'axios'

export default class CallService {
  getTTSResponse = async (history, listProduct, listCommand) => {
    const requestData = {
      history: history,
      order_data: listCommand,
      products_data: listProduct,
    }

    try {
      const response = await axios.post(
        // 'http://localhost:50000/tts-response',
        'https://ai.semecloud.tech/tts-response',
        requestData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob', // Indiquer que la réponse est un objet blob
        },
      )
      if (response.status === 200) {
        return response
      } else {
        console.error(
          'Erreur lors de la requête:',
          response.status,
          response.statusText,
        )
        return null
      }
    } catch (error) {
      console.log('Erreur lors de la requête TTS:', error)
      return null
    }
  }

  getIAResponse = async (
    audioFile,
    conversationID,
    listProduct,
    listCommand,
  ) => {
    const formData = new FormData()
    formData.append('audio', audioFile)
    formData.append('conversation_id', conversationID)
    formData.append('order_data', listCommand)
    formData.append('products_data', listProduct)

    try {
      const response = await axios.post(
        // 'http://localhost:50000/voice-chat-demo',
        'https://ai.semecloud.tech/voice-chat-demo',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob', // Indiquer que la réponse est un objet blob
        },
      )
      if (response.status === 200) {
        return response
      } else {
        console.error(
          'Erreur lors de la requête:',
          response.status,
          response.statusText,
        )
        return null
      }
    } catch (error) {
      console.log("Erreur lors de l'envoi du fichier audio:", error)
      return null
    }
  }

  getTranscription = async audioFile => {
    const formData = new FormData()
    formData.append('audio', audioFile)

    try {
      const response = await axios.post(
        // 'http://localhost:50000/transcript-voice',
        'https://ai.semecloud.tech/transcript-voice',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          // responseType: 'blob', // Indiquer que la réponse est un objet blob
        },
      )
      if (response.status === 200) {
        return response.data
      } else {
        console.error(
          'Erreur lors de la requête:',
          response.status,
          response.statusText,
        )
        return null
      }
    } catch (error) {
      console.log("Erreur lors de l'envoi du fichier audio:", error)
      return null
    }
  }

  /*
  getIAResponse = async body => {
    try {
      const res = await fetch('http://localhost:50000/voice-chat-demo', {
//      const res = await fetch('https://ai.semecloud.tech/voice-chat-demo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer sk_obNxIgSsOZWUn3FRxmyJy9USUzQ11n72mDxZGqObzYlU',
        },
        body: JSON.stringify(body),
      })
      return await res.json()
    } catch (err) {
      console.log(err)
    }
  }
  */
}
export const callService = new CallService()
