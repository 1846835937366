import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const PasswordComponent = () => {
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handlePasswordSubmit = e => {
    e.preventDefault()

    // Vérifier le mot de passe (remplacez 'votreMotDePasse' par le mot de passe souhaité)
    if (password === 'devDemo1') {
      navigate('/demo')
    } else {
      alert('Mot de passe incorrect')
    }
  }

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handlePasswordSubmit}>
        <label style={styles.label}>
          Mot de passe:
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            style={styles.input}
          />
        </label>
        <button type="submit" style={styles.button}>
          Valider
        </button>
      </form>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  label: {
    marginBottom: '10px',
  },
  input: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginBottom: '10px',
  },
  button: {
    padding: '8px 16px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
}

export default PasswordComponent
