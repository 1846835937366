import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import FormComponent from '../form/form-component'

const ModalComponent = props => {
  const {
    title,
    opened,
    close,
    body,
    formValidate,
    initialForm,
    formSubmit,
    formLoader,
  } = props

  return (
    <div>
      <Modal opened={opened} onClose={close} title={title} centered>
        <FormComponent
          formArray={body}
          validForm={formValidate}
          initialForm={initialForm}
          onSubmit={formSubmit}
          formLoader={formLoader}
        />
      </Modal>
    </div>
  )
}
ModalComponent.propTypes = {
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  body: PropTypes.array.isRequired,
  formValidate: PropTypes.object,
  initialForm: PropTypes.object,
  formSubmit: PropTypes.func,
  formLoader: PropTypes.bool,
}
export default ModalComponent
