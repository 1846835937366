export default class ProductService {
  listProduct = () => {
    // localStorage.removeItem('all_products')
    const data = localStorage.getItem('all_products')
    const dataMap = JSON.parse(data)
    let prods = dataMap ?? []
    console.log('actual products list is', prods)
    return prods
  }

  createProduct = product => {
    product.id = new Date().getMilliseconds()
    const newProduct = [...this.listProduct(), product]
    localStorage.setItem('all_products', JSON.stringify(newProduct))
    console.log('actual products list is', JSON.stringify(newProduct))
    return product
  }

  removeProduct = product => {
    const products = this.listProduct()
    const new_list = products.filter(element => element.id !== product.id)
    localStorage.setItem('all_products', JSON.stringify(new_list))
    console.log('new products list is', JSON.stringify(new_list))
    return product
  }

  getIAResponse = async body => {
    console.log('the history is', body)
    try {
      // const res = await fetch('http://localhost:50000/text-chat-demo', {
      const res = await fetch('https://ai.semecloud.tech/text-chat-demo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Bearer sk_obNxIgSsOZWUn3FRxmyJy9USUzQ11n72mDxZGqObzYlU',
        },
        body: JSON.stringify(body),
      })
      return await res.json()
    } catch (err) {
      console.log(err)
    }
  }

  getIAImageInterpretation = async body => {
    try {
      // const res = await fetch('http://localhost:50000/image-interpreter-demo', {
      const res = await fetch(
        'https://ai.semecloud.tech/image-interpreter-demo',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization:
              'Bearer sk_obNxIgSsOZWUn3FRxmyJy9USUzQ11n72mDxZGqObzYlU',
          },
          body: JSON.stringify(body),
        },
      )
      return await res.json()
    } catch (err) {
      console.log(err)
    }
  }
}
export const productService = new ProductService()
